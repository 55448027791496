.dark-mode .au-button {
    color: white;
    background-color: transparent !important;
}

.au-button {
    cursor: pointer;
}

.au-button:hover {
    color: var(--au-orange);
}

.dark-mode .au-button:hover {
    color: var(--au-orange);
}